<template>
  <v-card outlined elevation="0">
    
    <v-toolbar dense color="transparent" elevation="0">
      <router-link :to="'/admin/create-entity/reservation/' + resa._id" style="text-decoration:none">
        <small :class="colorStatus + '--text'"><v-icon small :color="colorStatus">mdi-cart-arrow-down</v-icon> n°{{ resa._id }}</small>
      </router-link>
      <v-spacer/>
      <v-btn :to="'/admin/create-entity/reservation/' + resa._id"
              elevation="0" icon small class="mr-0"
              title="Afficher / éditer la commande">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </v-toolbar>

    <v-col cols="12" class="pt-0 px-4">
      

      <b>{{ resa.name }}</b> - 
      <small>{{ resa.email }}</small><br>
      <small>{{ resa.streetAddress }}, {{ resa.country }}, {{ resa.postalCode }}, {{ resa.city }}</small><br>

      <small class="grey--text" v-if="resa.created != null">
        Créé 
        <time-ago :datetime="resa.created" style="font-size:11px !important;"
                  :refresh="60" long :locale="$vuetify.lang.current">
        </time-ago>
      </small>
      
      <small class="grey--text" v-if="resa.updated != null">
        <span v-if="resa.created != null"> - </span>
        Modifié
        <time-ago :datetime="resa.updated" style="font-size:11px !important;"
                  :refresh="60" long :locale="$vuetify.lang.current">
        </time-ago>
      </small>


      <v-row class="mt-2 mb-0">
        <template v-for="(prod, p) in resa.products">
          <v-col cols="12" :key="p">
            <v-card outlined>
              <v-row>
                <v-col cols="3" sm="3">
                  <v-img :src="baseUrl() + prod.image" aspect-ratio="1" width="100%"/>
                </v-col>
                <v-col cols="9" sm="9" class="pt-4 mt-md-2">
                  <b>{{ prod.name }}</b><br>{{ prod.price }}€ 
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>
      <v-row class="mt-2 mb-0">
        <template v-for="(repro, p) in resa.reproductions">
          <v-col cols="12" :key="p">
            <v-card outlined>
              <v-row>
                <v-col cols="3" sm="3">
                  <v-img :src="baseUrl() + repro.product.image" aspect-ratio="1" width="80%" class="ma-4" style="border:3px solid black;"/>
                </v-col>
                <v-col cols="9" sm="9" class="pt-4 mt-md-2">
                  <b>{{ repro.product.name }}</b> x{{repro.quantity}}
                  <br> 
                  <small>
                    <b class="red--text">Reproduction {{ repro.reproConfig.type }}</b> 
                    {{ repro.reproConfig.sizeStr }}<br>
                    Quantité : {{repro.quantity}} 
                  </small>
                  <br>{{ repro.reproConfig.price * repro.quantity }}€ <small>+ {{ repro.reproConfig.fraisDePort }}€ fp</small>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </v-row>


      <v-alert border="left" outlined dense >
        <v-icon color="" class="mr-2">mdi-comment</v-icon> 
        <small>{{ resa.comment != null && resa.comment.length > 0 ? resa.comment : '- Aucun message' }}</small>
      </v-alert> 

      <v-row class="mt-0">

        <v-col cols="12" class="py-0">
          
          
          <v-alert border="left" color="blue" outlined dense v-if="resa.status=='OPEN'">
            <small>En attente de paiement</small>
          </v-alert>
          <v-alert border="left" color="green" outlined dense v-if="resa.status=='PAID'">
            <small>Total payé</small>
          </v-alert>
          <v-alert border="left" color="amber" outlined dense v-if="resa.status=='PAIDFEES'">
            <small>Frais supplémentaires payé</small>
          </v-alert>
          <v-alert border="left" color="teal" outlined dense v-if="resa.status=='WAIT_PAYFEES'">
            <small>En attente du paiement des frais supplémentaires</small>
          </v-alert>
        </v-col>

        <v-col cols="12" md="6" class="text-left">
          <small>
              <v-divider/>
              Frais port : {{ resa.fraisDePort }}€
              <v-divider/>
              Frais supp : {{ resa.additionalFees }}€
              <v-divider/>
              Total produit(s) : {{ resa.totalPrice - resa.fraisDePort - resa.additionalFees }}€
              <v-divider/>
          </small>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <small>
              <v-divider/>
              Total résa : {{ resa.totalPrice }}€
              <v-divider/>
              Total payé : {{ resa.totalPaid }}€
              <v-divider/>
              Reste à payer : <b>{{ resa.totalPrice - resa.totalPaid }}€</b>
              <v-divider/>
          </small>
        </v-col>

      </v-row>
    </v-col>

    <v-col cols="12" class="pa-0"><v-divider/></v-col>

    <v-toolbar dense  elevation="0">
      
      <v-btn dark color="blue" small outlined
                v-if="resa.status=='OPEN'"
                @click="sendMailPaiement()"
                elevation="0">
            <v-icon small class="mr-1">mdi-email</v-icon>
            Renvoyer
          </v-btn>
          <v-btn dark color="teal" small
                v-if="resa.status=='PAID'"
                @click="showDialogAddFees = true"
                elevation="0" outlined>
            <v-icon small class="mr-1">mdi-credit-card-plus</v-icon>
            Frais supp
          </v-btn>
      <v-spacer/>
      

      <v-btn text @click="sentResa()" 
              color="green" v-if="resa.status == 'PAID' || resa.status == 'PAIDFEES'">
              Expédié
      </v-btn>
      <v-btn text v-else
              @click="cancelResa()" color="red" >
              Annuler Résa
      </v-btn>
    </v-toolbar>

    <v-dialog v-model="showDialogAddFees" max-width="550"
              :fullscreen="$vuetify.breakpoint.width < 600">
      <v-card outlined>
        <v-toolbar color="transparent" elevation="0">
          Ajouter des frais supplémentaires
          <v-spacer/>
          <v-btn icon outlined small @click="showDialogAddFees = false" class="mx-1"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-col class="pb-0">
          <small>Indiquer le montant des frais à ajouter, et cliquer sur Enregistrer :</small>
          <v-text-field v-model="amoutAddFees" label="Montant des frais supplémentaires..." suffix="€" outlined class="mt-4"></v-text-field>
        </v-col>
        <v-toolbar color="transparent" elevation="0">
          <v-spacer/>
          <v-btn @click="showDialogAddFees = false" outlined class="mx-1">Annuler</v-btn>
          <v-btn @click="addAdditionalFees()" color="teal" class="mx-1"><v-icon>mdi-content-save-outline</v-icon> Enregistrer</v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style>
.btn-action1{
  padding:8px;
  border-radius: 4px;
  color: white !important;
  background-color: #009688;
  text-decoration:none;
}
.mail-header{
  text-align:center;
  border-bottom: 1px solid #dfdfdf;
}
</style>


<script>
import core from '../../../plugins/core.js'
import axios from 'axios'
import {TimeAgo} from "vue2-timeago"
//import { i18n } from 'vue-lang-router';

export default {
    name: "card-resa",
    components: {
      TimeAgo
    },
    props: ['resa'],
    data: () => ({
      showDialogAddFees: false,
      amoutAddFees: "",
    }),
    mounted: function(){
    },
    methods: {
        async sendMailPaiement(){
          let res = await axios.post('/reservation/send-mail-paiement/' + this.resa._id)
          if(res.data.error == false){
            this.$root.$emit('reloadReservations')
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Le mail de paiement a bien été envoyé",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async addAdditionalFees(){
          console.log("addFees", isNaN(this.amoutAddFees))

          if(isNaN(this.amoutAddFees)) return 

          let addFees = parseFloat(this.amoutAddFees)
          
          let res = await axios.post('/reservation/add-additional-fees/' + this.resa._id, { fees: addFees })
          if(res.data.error == false){
            this.showDialogAddFees = false
            this.$root.$emit('reloadReservations')
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Les frais supplémentaire ont été enregistrés, et le mail a bien été envoyé",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async cancelResa(){
          let res = await axios.post('/reservation/cancel/' + this.resa._id)
          if(res.data.error == false){
            this.$root.$emit('reloadReservations')
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "La réservation a bien été annulée",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })
          }else{
            this.showNotifError(res.data.msg)
          }
        },
        async forcePayment(){

        },
        async sentResa(){
          let res = await axios.post('/reservation/sent/' + this.resa._id)
          if(res.data.error == false){
            this.$store.dispatch('app/openDialogMailDev', res.data.emailParams)
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'green', 
                                  title: "Réservation envoyée ! " + this.resa.totalPrice + "€ dans la besace !",
                                  text: res.data.msg,
                                  icon: 'thumb-up' })

            if(!this.showPopupMail) 
              this.$root.$emit('reloadReservations')
      
            console.log("content", res.data.emailParams.html)
          }else{
            this.$store.dispatch('app/openSnackbar', 
                                { show: true, color:'red', 
                                  title: "Une erreur est survenue pendant l'opération",
                                  text: res.data.msg,
                                  icon: 'alert' })
          }
        },
        closePopupMail(){
          this.showPopupMail = false
          this.$root.$emit('reloadReservations')
        },
        baseUrl(){ return core.baseUrl() },
        showNotifError(msg){
          this.$store.dispatch('app/openSnackbar', 
                      { show: true, color:'red', 
                        title: "Une erreur est survenue pendant l'opération",
                        text: msg, icon: 'alert' })
        }
    },
    computed:{
      totalResa(){ 
        let total = 0
        this.resa.products.forEach((resa)=>{
          total += resa.price
        })
        total += this.resa.fraisDePort
        return total
      },
      colorStatus(){ 
        if(this.resa.status == 'OPEN') return 'blue'
        if(this.resa.status == 'PAID') return 'green'
        if(this.resa.status == 'PAIDFEES') return 'amber'
        if(this.resa.status == 'WAIT_PAYFEES') return 'teal'
        return 'grey'
      }
    }
};
</script>